












































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class TimeAutionForm extends Vue {}
